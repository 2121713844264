<script lang="ts">
import { HsbApi } from '@/api'
import { useProject } from '@/composables/useProject'
import { OverheadLineId } from '@/model'
import { HsbLibObjectEnum } from '@/model/calculation'
import { useCalculationStore } from '@/stores/calculation'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { triggerDownload } from '@/util/helpers'
import { ProjectMediaType } from '@gridside/hsb-api'
import { ElMessage } from 'element-plus'
import { defineComponent } from 'vue'

const CalculationApi = HsbApi.calculation

export default defineComponent({
  name: 'ExportTab',

  data: () => ({
    loadingOverheadLine: false,
    loadingMeshedMediumSimulation: false
  }),

  setup() {
    const { project, projectId } = useProject()
    return {
      project,
      projectId,
      calculationStore: useCalculationStore(),
      overheadLineStore: useOverheadLineStore()
    }
  },

  computed: {
    operationStates() {
      return this.calculationStore.current?.studyCase?.operationStates || []
    },

    overheadLineItems() {
      return this.overheadLineStore.items.map((item) => ({
        value: item.overheadLine.id,
        label: item.overheadLine.name
      }))
    },

    subResultItems(): Array<{ value?: number; label: string }> {
      const subresults =
        this.calculationStore.current?.studyCase?.operationStates.map((opState, index) => ({
          value: index,
          label: `Teilergebnis Betriebszustand ${index + 1}`
        })) || []
      if (this.project?.mediaType === ProjectMediaType.PIPE) {
        return [{ value: undefined, label: 'Gesamtergebnis ' }, ...subresults]
      } else {
        return subresults
      }
    }
  },

  methods: {
    async downloadMeshedMediumSimulation(operationState: number | undefined) {
      this.loadingMeshedMediumSimulation = true
      try {
        await this.downloadResults(HsbLibObjectEnum.MESHED_MEDIUM_SIMULATION, operationState)
      } finally {
        this.loadingMeshedMediumSimulation = false
      }
    },

    async downloadOverheadLine(overheadLineId: OverheadLineId) {
      this.loadingOverheadLine = true
      try {
        await this.downloadResults(HsbLibObjectEnum.OVERHEADLINE, undefined, overheadLineId)
      } finally {
        this.loadingOverheadLine = false
      }
    },

    async downloadResults(
      type: HsbLibObjectEnum,
      operationState?: number,
      overheadLineId?: OverheadLineId
    ) {
      const calculationId = this.calculationStore.current?.id
      if (!calculationId) {
        return
      }
      try {
        const response = (await CalculationApi.getCalculationHsbLibObject(
          this.projectId,
          calculationId,
          type,
          operationState,
          overheadLineId
        )) as BlobPart

        triggerDownload(
          response,
          `${this.projectId}_${type}_${Intl.DateTimeFormat('de', {
            dateStyle: 'short'
          })
            .format(new Date())
            .replaceAll('.', '_')}`,
          'application/json'
        )
      } catch (e) {
        ElMessage({
          message: 'Daten nicht verfügbar',
          duration: 5000
        })
      }
    }
  }
})
</script>

<template>
  <div>
    <p-form-section title="HSBlib-Berechnungsdaten">
      <!-- Meshed Medium Simulation -->
      <el-dropdown class="mr-4">
        <el-button class="w-64" :loading="loadingMeshedMediumSimulation">
          MeshedMediumSimulation
          <el-icon class="el-icon--right"><ChevronDownIcon /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu class="min-w-64">
            <el-dropdown-item
              v-for="(item, index) in subResultItems"
              :key="index"
              @click="downloadMeshedMediumSimulation(item.value)"
            >
              <el-icon class="el-icon--left pr-2" size="24"><DownloadIcon /></el-icon>
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- Overhead line OHL -->
      <el-dropdown>
        <el-button class="w-48" :loading="loadingOverheadLine">
          OverheadLine
          <el-icon class="el-icon--right"><ChevronDownIcon /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu class="min-w-48">
            <el-dropdown-item
              v-for="item in overheadLineItems"
              :key="item.value"
              @click="downloadOverheadLine(item.value)"
            >
              <el-icon class="el-icon--left pr-2" size="24"><DownloadIcon /></el-icon>
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </p-form-section>
  </div>
</template>

<style scoped lang="css"></style>
